<template>
  <LoginStyle1>
    <div class="Registercontent">
      <h6>欢迎您，选择<em>ABC智能管理系统</em></h6>
      <p class="LeftLogin" @click="GoLogin">登陆</p>
      <p class="RightLogin">注册</p>
      <div>
        <div class="Regileft">
          <label for="RegiProductType">产品类型:</label>
          <label for="RegiCompanyName">店铺名称:</label>
          <label for="RegiUserName">用户名:</label>
          <label for="RegiTeliNo">手机号:</label>
          <label for="RegiCheckNo">验证码:</label>
          <label for="RegiPassword">密码:</label>
          <label for="RegiPasswordConfirm">密码确认:</label>
          <!-- <label for="RechareCode">充值码:</label> -->
        </div>
        <div class="Regiright">
          <select id="RegiProductType" v-model="RegisterViewModelUpdate.ProductTypeName">
            <option value="ForCommon">通用型</option>
            <option value="ForRepair">维修行业定制版</option>
          </select>
          <input id="RegiCompanyName" type="text" :maxlength="10" placeholder="请输入店铺名" v-model="RegisterViewModelUpdate.CompanyNameAbbre" />
          <input id="RegiUserName" type="text" :maxlength="10" placeholder="请输入用户名(推荐管理员)" v-model="RegisterViewModelUpdate.UserName" />
          <input id="RegiTeliNo" type="tel" :maxlength="11" placeholder="请输入手机号" v-model="RegisterViewModelUpdate.PhoneNo" @blur="checkNumber" />
          <input id="RegiCheckNo" type="tel" :maxlength="6" placeholder="请输入验证码" v-model="RegisterViewModelUpdate.CheckNo" />
          <input id="RegiPassword" type="password" :maxlength="20" placeholder="请输入密码" v-model="RegisterViewModelUpdate.Password" />
          <input id="RegiPasswordConfirm" type="password" :maxlength="20" placeholder="请确认密码" v-model="RegisterViewModelUpdate.PasswordConfirm" />
          <!-- <input id="RechareCode" type="text" :maxlength="20" placeholder="无充值码将以普通版登录" v-model="RegisterViewModelUpdate.RechargeCode" /> -->
          <div class="floatDiv"><span class="getMessageCode" v-show="waitTime == 0" @click="getTeliCode()">获取验证码</span><span class="remarkContainer" v-show="waitTime > 0">{{waitTime}}秒后重新申请</span></div>
        </div>

      </div>
      <div class="submitContainer">
        <input class="Registersubmit" type="submit" value="免费注册试用" @click.prevent="DocumentTypeManageAdd()" />
      </div>
    </div>
    <div class="popWindowcloak" v-show="$store.state.netloading">
      <img class="loadingimg" src="../../src/assets/image/Padding/loading1.gif" alt="加载中">
    </div>
  </LoginStyle1>
</template>

<script>
import LoginStyle1 from "@/components/Common/LoginStyle1.vue";
import router from '@/router/index.js';
import { reactive, ref } from '@vue/reactivity';
import { registerUser } from '@/hooks/loginAffair';
import { useStore } from "vuex";
import { onUnmounted } from '@vue/runtime-core';
import { isRealNum } from '@/hooks/tools';
import requestData from '@/hooks/requestData';
export default {
  setup() {
    const store = useStore();

    let RegisterViewModelUpdate = reactive({ CompanyNameAbbre: '', UserName: '', Password: '', PasswordConfirm: '', RechargeCode: '', PhoneNo: '', CheckNo: '',ProductTypeName:'ForCommon' });
    const GoLogin = () => {
      router.push({ path: '/login' })
    };

    const DocumentTypeManageAdd = () => {
      registerUser(RegisterViewModelUpdate, sendRegiInfo)
    };

    const sendRegiInfo = () => {
      store.commit('fillRegis', RegisterViewModelUpdate)
    }
    //初始化
    let enterRegister = (e) => {
      if (e.keyCode == 13) { DocumentTypeManageAdd() }
    }
    window.addEventListener('keyup', enterRegister);
    //离开时清理
    onUnmounted(() => {
      window.removeEventListener('keyup', enterRegister)
    })

    let waitTime = ref(0);

    let idForTime;

    const myreg = /^[1][2,3,4,5,6,7,8,9][0-9]{9}$/;

    const checkNumber = (e) => {

      if (!myreg.test(e.target.value) && e.target.value != '') {
        alert('手机号码填写有误')
        RegisterViewModelUpdate.PhoneNo = ''
        return;
      }
    }

    const getTeliCode = () => {
      if (!myreg.test(RegisterViewModelUpdate.PhoneNo)) {
        alert('手机号码填写有误')
        return;
      }
      const config = {
        method: "get",
        url: "/Register/GetMessageCode",
        params: {
          telNo: RegisterViewModelUpdate.PhoneNo,
        }
      };
      requestData.GetCache(config).then(res => {
        if (res == 'pass') {
          waitTime.value = 60

          idForTime = setInterval(() => {
            if (waitTime.value == 0) {
              if (idForTime) {
                clearInterval(idForTime)
              }
              return
            }
            waitTime.value--;
          }, 1000)
        }
        else {
          alert(res)
          return
        }
      })
    }

    return {
      GoLogin,
      DocumentTypeManageAdd,
      RegisterViewModelUpdate,
      sendRegiInfo,
      waitTime,
      getTeliCode,
      checkNumber
    }
  },
  components: {
    LoginStyle1,
  },
};
</script>

<style scoped lang="less">
.Regileft {
  width: 19%;
  float: left;
}
.Regiright {
  width: 81%;
  float: right;
  position: relative;
}

h6 {
  line-height: 400%;
  color: #a6a6a6;
  font-size: 1.2rem;
}
.Registercontent {
  position: relative;
  float: right;
  width: 90%;

  background-color: transparent;
}
em {
  color: #6cdaf7;
  font-size: 1.2rem;
}
input,
select {
  margin-top: 1.58rem;
  width: 80%;
  height: 2.3rem;
  font-size: 1rem;
  color: #455171;
  font-weight: 700;
  text-indent: 10px;
  line-height: 200%;
}
.remarkContainer {
  font-size: 0.8rem;
}
.floatDiv {
  position: absolute;
  left: 10rem;
  top: 12.45rem;
}

.getMessageCode:hover {
  cursor: pointer;
}
.getMessageCode {
  color: #409eff;
}

label {
  display: block;
  margin-top: 1.5rem;
  color: #cccccc;
  font-size: 1rem;
  height: 2.38rem;
  line-height: 2.38rem;
}

.Registercontent div {
  margin-top: 1.25rem;
}
.submitContainer {
  width: 100%;
  height: 100%;
}

.Registersubmit {
  margin-top: 3.13rem;
  background-color: #152449;
  width: 84%;
  cursor: pointer;
  border: none;
  color: #fff;
}

.Registercontent p {
  display: inline-block;
  font-size: 1rem;
  margin-right: 2.88rem;
  cursor: pointer;
  font-weight: 700;
}

.RightLogin {
  position: relative;
  box-sizing: border-box;
  color: #152449;
}

.RightLogin::after {
  display: block;
  width: 1.13rem;
  border-bottom: 4px solid #152449;
  position: absolute;
  content: "";
  left: 0.5rem;
  top: 1.69rem;
}

.Registercontent p:hover {
  position: relative;
  box-sizing: border-box;
  color: #152449;
}

.Registercontent p:hover::after {
  display: block;
  width: 1.13rem;
  border-bottom: 4px solid #152449;
  position: absolute;
  content: "";
  left: 0.5rem;
  top: 1.69rem;
}
</style>